import { combineEpics, ofType } from "redux-observable";
import _ from "lodash";
import _get from "lodash/get";
import Alert from "react-s-alert";
import { of } from "rxjs";
import { map, delay, mergeMap, catchError, takeUntil } from "rxjs/operators";
// import { isMobile } from "react-device-detect";
import history from "../history";
import * as req from "./service";
import * as action from "./actions";
import store from '../store'
import * as c from "./constants";
import * as con from "../modules/auth/constants";

const getList = (action$) =>
  action$.pipe(
    ofType(c.GET_LIST),
    mergeMap(({ key, url, params, callback }) =>
      req.get(url, params).pipe(
        map(action.toList(key)),
        map((x) => {
          setTimeout(() => {
            if (callback) callback(_.pick(x, ["data", "pager", "res"]));
          }, 50);
          return x;
        }),
        catchError(action.listError(key)),
        takeUntil(action$.pipe(ofType(c.CANCEL)))
      )
    )
  );

const getItem = (action$) =>
  action$.pipe(
    ofType(c.GET_ITEM),
    mergeMap(({ key, url, params, callback }) =>
      req.get(url, params).pipe(
        map(action.toItem(key)),
        map((x) => {
          setTimeout(() => {
            if (callback) callback(_.pick(x, ["data", "res"]));
          }, 50);
          return x;
        }),
        catchError(action.itemError(key)),
        takeUntil(action$.pipe(ofType(c.CANCEL)))
      )
    )
  );

const findPost = (action$) =>
  action$.pipe(
    ofType(c.FIND_POST),
    mergeMap(({ key, url, payload, callback, options = {}, errCallback }) =>
      req.post(url, payload).pipe(
        map(
          action.withMessage(
            key,
            typeof options.defaultMessage !== "undefined"
              ? options.defaultMessage
              : "Action Successful!"
          )
        ),
        map((x) => {
          setTimeout(() => {
            const { data = {}, included = [] } = x.data;
            if (callback)
              callback({
                data: action.flatIncludedToItem(data, included),
                res: x,
              });
          }, 50);
          return x;
        }),
        map((x) => {
          const { data = {} } = x.data;
          return action.setItem(key, data);
        }),
        catchError(req.onErr(key, errCallback)),
        takeUntil(action$.pipe(ofType(key))),
        takeUntil(action$.pipe(ofType(c.CANCEL)))
      )
    )
  );

const createItem = (action$) =>
  action$.pipe(
    ofType(c.CREATE_ITEM),
    mergeMap(({ key, url, payload, callback, options = {}, errCallback }) =>
      req.post(url, payload).pipe(
        map(
          action.withMessage(
            key,
            typeof options.defaultMessage !== "undefined"
              ? options.defaultMessage
              : "Created!"
          )
        ),
        map((x) => {
          setTimeout(() => {
            const { data = {}, included = [] } = x.data;
            if (callback)
              callback({
                data: action.flatIncludedToItem(data, included),
                res: x,
              });
          }, 50);
          return x;
        }),
        catchError(req.onErr(key, errCallback)),
        takeUntil(action$.pipe(ofType(c.CANCEL)))
      )
    )
  );

const updateItem = (action$) =>
  action$.pipe(
    ofType(c.UPDATE_ITEM),
    mergeMap(({ key, url, payload, callback, options = {}, errCallback }) =>
      req.put(url, payload).pipe(
        map(
          action.withMessage(
            key,
            typeof options.defaultMessage !== "undefined"
              ? options.defaultMessage
              : "Updated!"
          )
        ),
        map((x) => {
          setTimeout(() => {
            const { data = {}, included = [] } = x.data;
            if (callback)
              callback({
                data: action.flatIncludedToItem(data, included),
                res: x,
              });
          }, 50);
          return x;
        }),
        catchError(req.onErr(key, errCallback)),
        takeUntil(action$.pipe(ofType(c.CANCEL)))
      )
    )
  );

const removeItem = (action$) =>
  action$.pipe(
    ofType(c.DELETE_ITEM),
    mergeMap(
      ({ key, url, callback, options = {}, payload = {}, errCallback }) =>
        req.remove(url, payload).pipe(
          map(
            action.withMessage(
              key,
              typeof options.defaultMessage !== "undefined"
                ? options.defaultMessage
                : "Removed!"
            )
          ),
          map((x) => {
            setTimeout(() => {
              if (callback) callback(_.pick(x, ["data"]));
            }, 50);
            return x;
          }),
          catchError(req.onErr(key, errCallback)),
          takeUntil(action$.pipe(ofType(c.CANCEL)))
        )
    )
  );

const uploadFormData = (action$) =>
  action$.pipe(
    ofType(c.UPLOAD_FORM_DATA),
    mergeMap(({ key, url, formData, callback }) =>
      req.postFormData(url, formData).pipe(
        map(action.withMessage(key, "File Uploaded!")),
        map((x) => {
          setTimeout(() => {
            if (callback) callback(_.pick(x, ["data"]));
          }, 50);
          return x;
        }),
        catchError(req.onErr(key)),
        takeUntil(action$.pipe(ofType(c.CANCEL)))
      )
    )
  );

const download = (action$) =>
  action$.pipe(
    ofType(c.DOWNLOAD),
    mergeMap(({ key, url, fileName, callback }) =>
      req.download(key, url, fileName, callback)
    ),
    map(({ key }) => ({
      type: c.DOWNLOADED,
      key,
    }))
  );

const randomDelay = (max, min) =>
  Math.floor(Math.random() * (max - min + 1)) + min;

const gotItemDelay = (action$) =>
  action$.pipe(
    ofType(c.GOT_ITEM_DELAY),
    delay(randomDelay(700, 350)),
    mergeMap(({ key, data, callback }) =>
      of([]).pipe(
        map(() => action.toItem(key)({ response: { data } })),
        map((x) => {
          setTimeout(() => {
            if (callback) callback(_.pick(x, ["data"]));
          }, 50);
          return x;
        }),
        catchError(action.itemError(key)),
        takeUntil(action$.pipe(ofType(c.CANCEL)))
      )
    )
  );

const gotListDelay = (action$) =>
  action$.pipe(
    ofType(c.GOT_LIST_DELAY),
    delay(randomDelay(700, 350)),
    mergeMap(({ key, data, callback }) =>
      of([]).pipe(
        map(() => action.toList(key)({ response: { data } })),
        map((x) => {
          setTimeout(() => {
            if (callback) callback(_.pick(x, ["data", "pager"]));
          }, 50);
          return x;
        }),
        catchError(action.itemError(key)),
        takeUntil(action$.pipe(ofType(c.CANCEL)))
      )
    )
  );

const consolidateError = (action$) =>
  action$.pipe(
    ofType("ON_ERROR"),
    map((res) => {
      const error = res.error || {};
      const { status } = error;
      let mobileDevice = '';

      const userAgent = navigator.userAgent || navigator.vendor || window.opera;

      if (/android/i.test(userAgent)) {
        mobileDevice = "Android";
      }

      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        mobileDevice = "iOS";
      }

      // const redirectDebounce = _.debounce((path) => {
      //   window.location.href = path;
      // }, 100);

      if (status === 401) {
        const message = _get(error, "response.message");
        const reason = _get(error, "response.errors.0.detail");
        if (
          message === "Token expired"
        ) {
          if (mobileDevice === 'Android') {
            history.push("/service-unavailable");
            localStorage.clear();
            action.setItem(con.AUTH, { token: null, isAuthenticated: false });
          }
          if (mobileDevice === 'iOS') {
            history.push("/service-unavailable");
            // redirectDebounce("/service-unavailable");
            window.location.href = "/service-unavailable";
            localStorage.clear();
            action.setItem(con.AUTH, { token: null, isAuthenticated: false });
          }
          if (_.isEmpty(mobileDevice)) {
            localStorage.clear();
            action.setItem(con.AUTH, { token: null, isAuthenticated: false });
            window.location.href="/logout"
            Alert.error(message || reason || "Access denied!");
          }
        }
        if (
          message !== "Token expired" ||
          message !== "No bearer token" ||
          message !== "Unauthenticated."
        ) {
          Alert.error(message || reason || "Access denied!");
        }
      }
      if (status === 404) {
        const message = _get(error, "response.message");
        const reason = _get(error, "response.errors.0.detail");
        Alert.error(message || reason || "Access denied!");
      }

      if (status === 403) {
        const reason = _get(error, "response.errors.0.detail");
        const err =
          _get(error, "response.message") || reason || "Oops! Something went wrong";
        Alert.error(err);
      }
      if (status === 422) {
        Alert.error(
          _.get(Object.values(_get(error, "response.errors")), "0") ||
          _get(error, "response.message") ||
          _get(error, "response.errors.0.detail") ||
          _get(error, "response.0.message") ||
          _get(error, "response.reason") ||
          "Oops! Something went wrong"
        );
      }
      if (status === 405) {
        const err =
          _get(error, "response.errors.0.detail") ||
          "Oops! Something went wrong";
        Alert.error(err);
      }

      if (status === 400) {
        Alert.error(
          _get(error, "response.error") ||
          _get(error, "response.message") ||
          _get(error, "response.errors.0.detail") ||
          _get(error, "response.errors.0.details") ||
          _get(error, "response.0.message") ||
          _get(error, "response.reason") ||
          "Oops! Something went wrong"
        );
      }

      if (status === 500) {
        Alert.error(
          _get(error, "response.error") ||
          _get(error, "response.message") ||
          _get(error, "response.0.message") ||
          "Oops! Something went wrong"
        );
      }

      if (error && !status && typeof status !== "number") {
        console.log(error); // eslint-disable-line
        Alert.error(
          _get(error, "response.error") ||
          _get(error, "response.message") ||
          _get(error, "response.0.message") ||
          "Oops! Something went wrong"
        );
      }
      if (!error) {
        console.log("unable to parse error", error); // eslint-disable-line
      }

      return {
        type: "ON_ERROR_DONE",
      };
    })
  );

  const loginCreateItem = action$ =>
  action$.pipe(
    ofType(c.LOGIN_CREATE_ITEM),
    map(x => {
      store.dispatch({ type: x.key });
      return x;
    }),
    mergeMap(({ key, url, payload, callback, errCallback }) =>
      req.post(url, payload).pipe(
        map(action.toItem(key)),
        map(x => {
          setTimeout(() => {
            const { data = {}, included = [] } = x.data;
            if (callback)
              callback({
                data: action.flatIncludedToItem(data, included),
                res: x,
              });
          }, 50);
          return x;
        }),
        catchError(req.onErr(key, errCallback)),
        takeUntil(action$.pipe(ofType(key))),
        takeUntil(action$.pipe(ofType(c.CANCEL)))
      )
    )
  );

export default combineEpics(
  findPost,
  getList,
  getItem,
  createItem,
  updateItem,
  removeItem,
  uploadFormData,
  download,
  gotItemDelay,
  gotListDelay,
  consolidateError,
  loginCreateItem
);
